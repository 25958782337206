import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useUserIPGrabber } from '@cleverrealestate/clever-helpers';
import InlineCitation from '@cleverrealestate/clever-components-v2/dist/components/InlineCitation/InlineCitation';
import PaginatedArticles from '@cleverrealestate/clever-components-v2/dist/components/PaginatedArticles/PaginatedArticles';
import Layout from '../components/layout/Layout';
import SEO from '../components/meta/SEO';
import HeroSection from '../components/organisms/HeroSection';
import useGlobalTOCHandler from '../utils/useGlobalTOCHandler';
import renderContent from '../utils/renderContent';

const LandingPageTemplate = ({ pageContext }) => {
  useUserIPGrabber();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const {
    // Display Values
    heroSubtitle,
    structuredData,
    latestArticles,
    generateArchivePages,
    baseUrl,
    pageName,
    paginationMeta,
    currentPage,
    pageCount,
    // Content Values
    title,
    renderContext,
    // SEO Values
    slug,
    createdAtIso,
    modifiedAtIso,
    noIndex,
    metaTitle,
    metaDescription,
    articleImage,
    contentTopic,
    contentType,
  } = pageContext;

  useGlobalTOCHandler();

  const publishDateMeta = [
    {
      name: 'article:published_time',
      content: createdAtIso,
    },
    {
      name: 'article:modified_time',
      content: modifiedAtIso,
    },
    {
      name: 'og:updated_time',
      content: modifiedAtIso,
    },
  ];

  return (
    <Layout>
      <SEO
        pathname={slug}
        title={metaTitle}
        description={metaDescription}
        canonical={slug}
        noIndex={noIndex}
        links={paginationMeta || []}
        publishDateMeta={publishDateMeta}
        breadcrumbs={null}
        structuredData={structuredData}
        image={articleImage.url}
        imageHeight={articleImage.height}
        imageWidth={articleImage.width}
        addWebsiteName={false}
        contentTopic={contentTopic}
        contentType={contentType}
      />

      <HeroSection
        heading={title}
        subheading={heroSubtitle}
        showZipForm={false}
        heroStyle="light"
      />

      <article
        id="article-content"
        className="w-full max-w-6xl py-6 mx-auto text-lg md:py-12 md:container"
      >
        {renderContext?.context[Object.keys(renderContext.context)[0]]
          ?.chunkType === 'jsx-inline-full-feature' && (
          <h3 className="px-4 mb-6 md:px-0 text-primary-500">
            Featured Articles
          </h3>
        )}

        {renderContent(renderContext.html, renderContext.context)}

        <div className="mt-12 paginated-articles">
          <h3 className="px-4 mb-6 md:px-0 text-primary-500">The Latest</h3>

          <PaginatedArticles
            urls={latestArticles}
            pageCount={pageCount}
            currentPage={currentPage}
            baseUrl={baseUrl}
            pageName={pageName}
            generateArchivePages={generateArchivePages}
          />
        </div>
      </article>

      {mounted && <InlineCitation />}
    </Layout>
  );
};

LandingPageTemplate.propTypes = {
  pageContext: PropTypes.any,
};

LandingPageTemplate.defaultProps = {
  pageContext: {},
};

export default LandingPageTemplate;
